//
// IMPORTS
//
@import "variables";

/* BASE RULES */
/**************/

body {
    padding-top: 70px;
    font-family: $verdana;
    font-size: $fontSize;
    line-height: 1.5;
    margin: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.full-width {
    width: 100%;
}

.left {
    text-align: left;
}

.h-center {
    text-align: center;
}

.v-center {
    position:relative;
    top:50%;
    transform: translateY(-50%);
}

.card-title {
  background-image: url('../images/music-top-30.jpg');
  background-size: cover;
}

.excerpt a {
  color: $black;
  text-decoration: none;
}

.excerpt a:hover {
  text-decoration: underline;
}

h1 {font-size: $h1}
h2 {font-size: $h2}
h3 {font-size: $h3}
h4 {font-size: $h4}
h5 {font-size: $h5}
h6 {font-size: $h6}

h1, h2, h3, h4, h5, h6 {
  font-family: $segoeUI;
  font-weight:400;
  margin: 32px 0 8px 0;
}

.wide {
  letter-spacing: 2px;
}

.content {
  flex: 1;
  padding: 0 32px 32px;
  max-width: $contentMaxWidth;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  overflow-wrap: break-word;
}

.content:after, .content:before {
  content: "";
  display: table;
}

.card {
  padding: 16px 0;
  ul {
    list-style: inside;
    padding-left: 0;
  }
}

div.col-sm-4 {
  padding-bottom: 15px;
}

/* -- LISTS -- */
.col-sm-4 ul {
  list-style-position: inside;
  padding-left: 0;
}

.col-sm-4 ul.left {
  display: inline-block;
}

.post ul {
  list-style-position: inside;
  padding-left: 0;
}

/* -- KONSERTDATABASEN (kdb) FORM -- */

.kdbform {
    max-width: 47ch;
    margin: 0 auto 1rem;

    .form-group {
      text-align: left;
      margin-bottom: 0px;
    }
    .control-label {
      text-align: left;
      padding-left: 0;
    }

    button {
      margin-top: 6px;
      width: 100%;
    }
    select, input {
      margin-bottom: 1rem;
    }
}

/* override breakpoint for bootstrap form */
@media (min-width: 480px) {
    .kdbform {
      .col-sm-4 {
        width: 33.33333333%;
        float: left;
      }
      .col-sm-8 {
         width: 66.66666667%;
         float: left;
      }
    }
    .form-horizontal .control-label {
      padding-top: 7px;
      margin-bottom: 0;
    }
}
/* -- KONSERTDATABASEN TABLE -- */

.kdb-wrapper {
    position: relative;
}
.kdb-scroll {
    position: absolute;
    width: 100%;
    overflow: auto;
    transform: rotateX(180deg);
    padding-top: $footerHeight;
}
@media (min-width: calc(#{$navbarBreakpoint} + 1px)){
  .kdb-scroll {
    position: static;
    overflow: visible;
    display: flex;
    justify-content: center;
  }
  .konsertdatabasen {
    max-width: max-content;
    width: calc(100vw - 64px);
    flex-shrink: 0;

    thead {
      position: sticky;
      top: calc(#{$footerHeight} + 54px); // 1px less than headerHeight vvv
    }
    thead th {
      border-top: 2px solid $white; // to separate from nav without showing table
    }                             // with 1px overlap because of flickering problems
  }
}
@media (max-width: $navbarBreakpoint) {
  .kdb-scroll {
    left: 0;
  }
  #kdbResult {
    min-width: 1200px;
  }
}
.konsertdatabasen {
  border-collapse: separate;
  transform: rotateX(180deg);
  border-spacing: 1px 0;

    th {
      text-align: center;
      white-space: nowrap;
      background-color: $darkGray;
      padding: 3px;

        a {
          color: $lightGray;
          cursor: pointer;
          display: block;
          text-decoration-line: none;
        }

        a:hover {
          color: $white;
          background-color: $black;
        }
    }

    tbody {
      text-align: left;
      font-size: $kdbFontSize;
    }

    tr:nth-child(2n) td {
      background-color: $lighterGray;
    }

    th:first-child {
      border-radius: 6px 0 0;
    }

    th:last-child {
      border-radius: 0 6px 0 0;
    }

    td {
        padding: .5rem 1ch;
    }
}

td.date, td.conductor, td.composer {
  white-space: nowrap;
}

.emptyTable {
  text-align: center;
}

.tableEnd {
  background-color: $darkGray !important;
  border-radius: 0 0 6px 6px;
}

/** -- LINKS -- */
a {
  color: $blue;
  text-decoration: underline;
}

a:hover {
    background-color: $lightGray;
    color: $black;
}

a.btn {
  text-decoration: none;
}

/* -- EXTERNAL CONTENT -- */
/* For å vise plakat/program fra skavlid.net */

.external-content {
    position:fixed;
    top:0;
    left:0;
    height:100vh;
    width:100vw;
    border:none;
}

/* -- IMAGE -- */
.index-img {
    width: 100%;
    height: 100px;
    object-fit: cover;
}
.max-full-width {
    max-width: 100%;
    height: auto;
    display: block;
    margin: auto;
}

/* -- NAVIGATION BAR -- */
#myNavbar {
    overflow: hidden;
}

.navbar {
    border-radius: 0;

    a {
       text-align: center;
       font-size: $h6;
       text-decoration: none;
    }
    a:hover {
        color: $white;
        background-color: transparent;
    }
}

.navbar-icon {
    float: left;
    padding: 14px 14px;
}

/** Force collapse in navbar before necessary to avoid it overflowing accidentally.
    Increase max-width when adding elements to menu, or remove the max-width criteria
    if the menu should always be collapsed. */
@media (max-width: $navbarBreakpoint){
    .navbar-header {
        float: none;
    }
    .navbar-left,.navbar-right {
        float: none !important;
    }
    .navbar-toggle {
        display: block;
    }
    .navbar-collapse {
        border-top: 1px solid transparent;
        box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
    }
    .navbar-fixed-top {
        top: 0;
        border-width: 0 0 1px;
     }
    .navbar-collapse.collapse {
        display: none!important;
    }
    .navbar-nav {
        float: none!important;
        margin-top: 7.5px;
     }
    .navbar-nav>li {
        float: none;
    }
    .navbar-nav>li>a {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .collapse.in{
        display:block !important;
    }
}

/** -- FOOTER -- */
.fixed-footer {
  footer {
    position:fixed;
    left: 0;
    bottom: 0;
  }
}
footer {
  margin-top: auto;
  flex-shrink: 0;
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: $lighterGray;
  color: $black;
  height: $footerHeight;
  text-align: center;
  font-size: $h5;
  a, a:focus, a:active {
    color: $black;
    display:inline-block;
  }
  p {
      font-size: $fontSize;
  }
}
@media (max-width:240px){
   footer {
     position:fixed;
     left: 0;
     bottom: 0;
   }
 }
