//
// VARIABLES
//

// Font groups:
$segoeUI: "Segoe UI", Arial, sans-serif;
$verdana: Verdana, sans-serif;

// Font sizes:
$fontSize: 14px;
$h1: 36px;
$h2: 30px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;
$kdbFontSize: 12px;

// Colors:
$blue: #4183C4;

// Grays:
$white: #fff;
$lighterGray: #f2f2f2;
$lightGray: #ddd;
$darkGray: #333;
$black: #000;

// Sizes:
$contentMaxWidth: 980px;
$navbarBreakpoint: 1080px;
$footerHeight: 100px;
